<template>
	<div
		class="full-height"
	>
		<div class="">
			<div
				class="flex-1 mr-10"
			>
				<h6 class="text-left color-identify under-line-identify">결제 합계</h6>
				<table
					class="table"
				>
					<tbody>
					<tr>
						<th>매출 시작일</th>
						<th>매출 종료일</th>
						<th>요청 기준일</th>
						<th>요청 완료일</th>
						<th>총 결제 금액</th>

						<th>요청건수</th>
						<th>요청완료</th>
						<th>요청실패</th>
					</tr>
					<tr>
						<td>{{ item.fromDate }}</td>
						<td>{{ item.toDate }}</td>
						<td>{{ item.requestDate }}</td>
						<td>{{ item.responseDate }}</td>
						<td>{{ item.amount }}</td>

						<td>{{ item.totalCount | makeComma }}건</td>
						<td>{{ item.successCount | makeComma }}건</td>
						<td>{{ item.failCount | makeComma }}건</td>
					</tr>
					</tbody>
				</table>
			</div>

			<div
				class="mt-30 flex-1 mr-10"
			>
				<div class="justify-space-between items-center">
					<h6 class="text-left color-identify under-line-identify">요청내역</h6>
					<div>

						<select
							slot="add"
							class="pa-5-10 mr-10"

							v-model="item_search.size"
							@change="getSearch(1)"
						>
							<option
								v-for="cnt in codes.list_cnt"
								:key="'cnt_' + cnt"
								:value="cnt"
							>{{ cnt }} 건씩 보기</option>
						</select>

						<button
							class="btn-success pa-5-10 vertical-middle mr-10"
							@click="toExcel"
						>EXCEL</button>
					</div>
				</div>
				<div style="overflow: auto; width: 100%">
					<table
						class="table"
						style="width:  max-content !important;"
					>
						<colgroup>
						</colgroup>
						<thead>
							<tr>
								<th>NO</th>
								<th>승인일시</th>
								<th>요청 기준일</th>
								<th>요청 완료일</th>
								<th>가맹점</th>

								<th>배대사 계좌번호</th>
								<th>예금주명</th>
								<th>입금자명</th>
								<th>승인번호</th>

								<th>승인금액</th>
								<th>정산금액</th>
								<th>요청상태</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(item, index) in item_list"
								:key="'list_' + index"
							>
								<td>{{ item.idx }}</td>
								<td>{{ item.approvalDate }}</td>
								<td>{{ item.requestDate ? item.requestDate : '-' }}</td>
								<td>{{ item.responseDate ? item.responseDate : '-' }}</td>
								<td>{{ item.shopName}}</td>

								<td>{{ item.bankName }} {{ item.account }}</td>
								<td>{{ item.accountHolder }}</td>
								<td>{{ item.depositor }}</td>
								<td>{{ item.approvalNo }}</td>

                                <td class="text-right">{{ item.amount | makeComma }}원</td>
                                <td class="text-right">{{ item.settlementAmount | makeComma }}원</td>
                                <td :class="'color-' + item.state_color" @click="showReason(item)">{{ item.state_name }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<Pagination
				:program="program"
				:align="'center'"
				:options="item_search"

				class="mt-auto"
				@click="getSearch"
			></Pagination>
		</div>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
	name: 'QuickTransferDetail',
	components: {Pagination},
	props: ['item']
	,data: function(){
		return {
			program: {

			}
			, item_search: {
				page: 1
				, requestDate: this.item.requestDate.replaceAll('.', '-').substring(0, 10)
				, size: 10
				, total_count: 0
			}
			, items: []
		}
	}
    , computed: {
        item_list: function () {
            let self = this
            return this.items.filter(function (item) {

                for (let i = 0; i < self.codes.transfer_state.length; i++) {
                    if (item.state === self.codes.transfer_state[i].code) {
                        item.state_name = self.codes.transfer_state[i].name
                        item.state_color = self.codes.transfer_state[i].color
                        break
                    }
                }

                return item
            })
        }
    }
	, methods: {
        showReason: function (item) {
            this.$emit('showReason', item)
        },
		getData: async function(){
			try{
				this.$layout.onLoading()

				let result = await this.$Axios({
					method: 'get'
					,url: 'quick/transfer_detail'
					,data: this.item_search
				})

				if(result.success){
					this.items = result.data.tableList.data
					this.item_search.total_count = result.data.tableList.totalCount

					this.$storage.setQuery(this.search)
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,getSearch: function(page){

			if(page){
				this.item_search.page = page
			}

			//this.$layout.push( { name: this.$route.name, params: this.$route.params, query: this.search})

			this.getData()
		}
		, toExcel: function(){
			this.$emit('excel', this.items)
		}
	}
	, created() {
        this.$layout = this.$root.$children[0].$children[0]
		this.getData()
	}
}
</script>

<style>

div::-webkit-scrollbar {
	width: 1px;
}
</style>












