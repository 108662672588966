<template>
    <div
        class="full-height pa-10-20"
    >
        <div class="flex-row justify-space-between align-center">
            <div class="flex-row align-center">
                <h6>{{ program.name }}</h6>
                <button
                    class="pa-5-10 vertical-middle ml-10"
                    :class="{ 'bg-identify' : search.quickType == 0 , 'bg-identify-outline' : search.quickType != 0 }"
                    @click="setQuickType(0)"
                >D+0
                </button>
                <button
                    class="pa-5-10 vertical-middle ml-10"
                    :class="{ 'bg-identify' : search.quickType == 1, 'bg-identify-outline' : search.quickType != 1 }"
                    @click="setQuickType(1)"
                >D+1
                </button>
            </div>
            <button
                @click="onRequest"
                class="box pa-4-10 bg-black color-white mr-10"
            >일괄 수동 요청
            </button>
        </div>

        <Search
            class="mt-10 box-ddd"
            :search="search"
            :option="search_option"

            @click="getSearch"
            @reset="reset"
            @setSearchDateType="setSearchDateType"
        >
            <select
                slot="add"
                class="pa-5-10 mr-10"

                v-model="search.state"
                @change="getSearch(1)"
            >
                <option
                    v-for="add in codes.transfer_state"
                    :key="'status_' + add.code"
                    :value="add.code"
                >{{ add.name }}
                </option>
            </select>
        </Search>

        <div class="mt-10 pa-10 box-ddd bg-white height-10 flex-column ">
            <div class="text-right">
                <template
                    v-if="false"
                >
                    <input v-model="account" class="box pa-4-10 mr-10" placeholder="채번 계좌번호" disabled/>
                    <button
                        @click="onAccount"
                        class="box pa-4-10 bg-red mr-10"
                    >채번 계좌번호 재호출
                    </button>
                </template>
                <button
                    v-if="false"
                    class="box mr-10 pa-4-10 size-px-12"
                    @click="toExcel"
                >
                    <v-icon small class="color-green ">mdi mdi-file-excel</v-icon>
                    <span class="vertical-middle">엑셀 다운로드</span></button>
                <select
                    class="pa-5-10 mr-10 size-px-12"
                    v-model="search.size"
                    @change="getSearch(1)"
                >
                    <option
                        v-for="cnt in codes.list_cnt"
                        :key="'cnt_' + cnt"
                        :value="cnt"
                    >{{ cnt }} 건씩 보기
                    </option>
                </select>
            </div>
            <table
                v-if="items.length > 0 && search.quickType == 0"
                class="mt-10 table table-even"
            >
                <colgroup>

                    <col width="60px"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>

                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>

                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="85px"/>
                </colgroup>
                <thead>
                <tr>
                    <th>NO</th>
                    <th>승인일시</th>
                    <th>요청 기준일</th>
                    <th>요청 완료일</th>
                    <th>가맹점명</th>

                    <th>은행</th>
                    <th>배대사 계좌번호</th>
                    <th>예금주명</th>
                    <th>입금자명</th>
                    <th>승인번호</th>

                    <th>승인금액</th>
                    <th>정산금액</th>
                    <th>요청상태</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, index) in item_list"
                    :key="'list_' + index"
                >
                    <td>{{ item.idx }}</td>
                    <td>{{ item.approvalDate }}</td>
                    <td>{{ item.requestDate }}</td>
                    <td>{{ item.responseDate }}</td>
                    <td class="text-left">{{ item.shopName }}</td>

                    <td class="text-left">{{ item.bankName }}</td>
                    <td class="text-left">{{ item.account }}</td>
                    <td class="text-left">{{ item.accountHolder }}</td>
                    <td class="text-left">{{ item.depositor }}</td>
                    <td>{{ item.approvalNo }}</td>
                    <td class="text-right">{{ item.amount | makeComma }}원</td>
                    <td class="text-right">{{ item.settlementAmount | makeComma }}원</td>

                    <td :class="'color-' + item.state_color" @click="showReason(item)">{{ item.state_name }}</td>
                </tr>
                </tbody>
            </table>
            <table
                v-else-if="items.length > 0 && search.quickType == 1"
                class="mt-10 table table-even"
            >
                <colgroup>

                    <col width="60px"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>

                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>

                    <col width="100px"/>
                </colgroup>
                <thead>
                <tr>
                    <th>NO</th>
                    <th>매출 시작일</th>
                    <th>매출 종료일</th>
                    <th>요청 기준일</th>
                    <th>요청 완료일</th>

                    <th>총 결제금액</th>
                    <th>총 정산금액</th>
                    <th>요청건수</th>
                    <th>요청완료</th>
                    <th>요청실패</th>

                    <th>상세</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, index) in item_list"
                    :key="'list_' + index"
                >
                    <td>{{ item.idx }}</td>
                    <td>{{ item.fromDate }}</td>
                    <td>{{ item.toDate }}</td>
                    <td>{{ item.requestDate }}</td>
                    <td>{{ item.responseDate }}</td>

                    <td class="text-right">{{ item.amount | makeComma }}원</td>
                    <td class="text-right">{{ item.totalCalAmount | makeComma }}원</td>
                    <td>{{ item.totalCount | makeComma }}</td>
                    <td>{{ item.successCount | makeComma }}</td>
                    <td>{{ item.failCount | makeComma }}</td>
                    <td>
                        <button
                            class="bg-identify pa-5-10 size-px-12"
                            @click="toDetail(item)"
                        >상세보기
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <div
                v-else
                class="full-height flex-column justify-center mt-10 top-line-identify"
            >
                <div class="text-center">
                    <v-icon
                        class="size-px-48 "
                    >mdi mdi-cloud-off-outline
                    </v-icon>
                    <br/>
                    <br/>
                    <div class="font-weight-bold size-px-24">No Data</div>
                </div>
            </div>

            <Pagination
                :program="program"
                :align="'center'"
                :options="search"

                class="mt-auto"
                @click="getSearch"
            ></Pagination>

        </div>

        <Excel
            v-if="is_excel"
            :excel_data="excel_data"

            @finish="is_excel = !is_excel"
        ></Excel>

        <Modal
            :is_modal="modal_cancel"
            :top="true"
            :bottom="true"

            title="배달비 결제 취소"
            content="해당 배달비 결제 내역을 취소하시겠습니까?"
            width="380px"
            content_class="ptb-30"

            @close="close"
            @click="cancel"
            @cancel="close"
        ></Modal>

        <Modal
            :is_modal="is_modal"
            :option="modal_option"
            :bottom="false"

            @close="close"
        >

            <QuickTransferDetail
                v-if="is_modal"
                slot="modal-content"
                :item="item"

                @showReason="showReason"
                @excel="toExcel"
            ></QuickTransferDetail>
        </Modal>

        <Modal
            :is_modal="is_on_account"
            :top="true"
            :bottom="true"

            title="채번 계좌번호 재호출"
            content="채번 계좌번호 재호출 요청을 하시겠습니까?"
            width="380px"
            content_class="ptb-30"

            @close="close"
            @cancel="close"
            @click="postAccount"
        ></Modal>

        <Modal
            :is_modal="is_on_request"
            :top="true"
            :bottom="true"

            title="일괄 수동 요청"
            content="요청 실패건을 일괄 수동 요청 하시겠습니까?"
            width="380px"
            content_class="ptb-30"

            @close="close"
            @cancel="close"
            @click="postRequest"
        ></Modal>
    </div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Excel from "@/components/Excel";
import Modal from "@/components/Modal";
import QuickTransferDetail from "@/view/Quick/QuickTransferDetail";

export default {
    name: 'QuickTransferList'
    , components: {QuickTransferDetail, Modal, Excel, Search, Pagination}
    , data: function () {
        return {
            user: [],
            program: {
                name: '배달비 지급이체 내역'
                , top: true
                , title: true
                , bottom: false
            }
            , search: {
                route: this.$route.name
                , page: this.$route.query.page ? this.$route.query.page : 1
                , size: this.$route.query.size ? this.$route.query.size : 10
                , search_type: this.$route.query.search_type ? this.$route.query.search_type : ''
                , keyword: this.$route.query.keyword ? this.$route.query.keyword : ''
                , total_count: 0
                , state: ''
                , sDate: ''
                , eDate: ''
                , startDate: ''
                , endDate: ''
                , search_date_type: 'weekly'
                , maxAmount: ''
                , minAmount: ''
                , isSuccess: ''
                , quickType: 0
            }
            , search_option: {
                sDate: true
                , eDate: true
                , search_type: [
                    {name: '아이디', column: 1}
                    , {name: '가맹점명', column: 2}
                    , {name: '승인번호', column: 3}
                    , {name: '결제금액', column: 4}
                ]
                , is_search_date_type: true
                , search_sub_none: true
            }
            , is_excel: false
            , excel_data: {
                name: '배달비 결제 내역'
                , header: [ // api 추가 필요
                    {key: 0, name: 'NO', column: 'idx'}
                    , {key: 0, name: '승인일시', column: 'approvalDate'}
                    , {key: 0, name: '요청 기준일', column: 'requestDate'}
                    , {key: 0, name: '요청 완료일', column: 'responseDate'}
                    , {key: 0, name: '가맹점', column: 'shopName'}
                    , {key: 0, name: '은행', column: 'bankName'}
                    , {key: 0, name: '계좌번호', column: 'account'}
                    , {key: 0, name: '예금주 명', column: 'accountHolder'}
                    , {key: 0, name: '입금자 명', column: 'depositor'}
                    , {key: 0, name: '승인번호', column: 'approvalNo'}
                    , {key: 0, name: '승인금액', column: 'amount'}
                    , {key: 0, name: '정산금액', column: 'state'}
                ]
                , content: null
            }
            , items: []
            , item: {}
            , summary: []
            , is_modal: false
            , modal_option: {
                title: '배달비 결제요청 상세보기'
                , top: true
                , slot_bottom: true
            }
            , modal_cancel: false
            , account: ''
            , is_on_account: false
            , is_on_request: false
        }
    }
    , computed: {
        item_list: function () {
            let self = this
            return this.items.filter((item) => {
                for (let i = 0; i < self.codes.transfer_state.length; i++) {
                    if (item.state === self.codes.transfer_state[i].code) {
                        item.state_name = self.codes.transfer_state[i].name
                        item.state_color = self.codes.transfer_state[i].color
                        break
                    }
                }

                if (!item.cancelDate) {
                    item.cancelDate = '-'
                }

                return item
            })
        }
    }
    , methods: {
        showReason: function (item) {
            this.$layout.setNotify({type: 'error', message: item.reason})
        },
        getData: async function () {
            try {
                this.$layout.onLoading()
                this.$set(this.search, 'startDate', this.search.sDate)
                this.$set(this.search, 'endDate', this.search.eDate)
                if (this.search.search_type) {
                    this.search.keywordType = this.search.search_type
                } else {
                    delete this.search.keywordType
                }
                if (this.search.quickType == 0) {
                    let result = await this.$Axios({
                        method: 'get'
                        , url: 'quick/transfer/0'
                        , data: this.search
                    })

                    if (result.success) {
                        console.log(result.data)
                        this.items = result.data.tableList.data
                        this.search.total_count = result.data.tableList.totalCount
                        this.summary = result.data.statistics

                        this.$storage.setQuery(this.search)
                    } else {
                        this.$layout.setNotify({type: 'error', message: result.message})
                    }
                } else {
                    let result = await this.$Axios({
                        method: 'get'
                        , url: 'quick/transfer'
                        , data: this.search
                    })

                    if (result.success) {
                        this.items = result.data.tableList.data
                        this.search.total_count = result.data.tableList.totalCount
                        this.summary = result.data.statistics

                        this.$storage.setQuery(this.search)
                    } else {
                        this.$layout.setNotify({type: 'error', message: result.message})
                    }
                }

            } catch (e) {
                this.$layout.setNotify({type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , setQuickType: function (type) {
            this.search.quickType = type
            this.search.state = ''
            this.getData()
        }
        , toDetail: async function (item) {
            this.item = item
            this.is_modal = true
        }
        , reset: function () {
            this.search = {
                route: this.$route.name
                , page: 1
                , size: 10
                , total_count: 0
                , search_type: ''
                , keyword: ''
                , state: ''
                , sDate: this.date.getLastDate(this.date.getToday('-'), 7, '-')
                , eDate: this.date.getToday('-')
                , search_date_type: 'weekly'
                , maxAmount: ''
                , minAmount: ''
                , quickType: this.search.quickType
            }
            this.getData()
        }
        , getSearch: function (page) {

            if (page) {
                this.search.page = page
            }

            //this.$layout.push( { name: this.$route.name, params: this.$route.params, query: this.search})

            this.getData()
        }

        , toExcel: function (items) {
            this.excel_data.content = items
            this.is_excel = true
        }
        , setSearchDateType: function (type) {
            this.search.page = 1
            this.search.search_date_type = type
        }
        , close: function () {
            this.is_modal = false
            this.modal_cancel = false
            this.is_on_account = false
            this.is_on_request = false
        }
        , cancel: async function () {
            try {
                this.$layout.onLoading()
                let result = await this.$Axios({
                    method: 'put'
                    , url: 'quick/payment/' + this.item.idx
                })

                if (result.success) {
                    this.$layout.setNotify({type: 'success', message: result.message})
                    this.close()
                    this.getSearch()
                } else {
                    this.$layout.setNotify({type: 'error', message: result.message})
                }
            } catch (e) {
                this.$layout.setNotify({type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , isCancel: function () {
            this.close()
            this.modal_cancel = true
        }
        , getAccount: async function () {
            try {
                this.$layout.onLoading()
                let result = await this.$Axios({
                    method: 'get'
                    , url: 'quick/account'
                })

                if (result.success) {
                    this.account = result.data
                } else {
                    this.$layout.setNotify({type: 'error', message: result.message})
                }
            } catch (e) {
                this.$layout.setNotify({type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , postAccount: async function () {
            try {
                this.$layout.onLoading()
                let result = await this.$Axios({
                    method: 'post'
                    , url: 'quick/account'
                })

                if (result.success) {
                    await this.getAccount()
                } else {
                    this.$layout.setNotify({type: 'error', message: result.message})
                }
            } catch (e) {
                this.$layout.setNotify({type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.close()
                this.$layout.offLoading()
            }
        }
        , postRequest: async function () {
            try {
                this.$layout.onLoading()
                let result = await this.$Axios({
                    method: 'post'
                    , url: 'quick/failed_transfer_request'
                })

                if (result.success) {
                    console.log(this.$tabs.items, 'quick transfer')
                    this.$tabs.refresh(null, true, false)
                    // await this.getData()
                } else {
                    this.$layout.setNotify({type: 'error', message: result.message})
                }
            } catch (e) {
                this.$layout.setNotify({type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.close()
                this.$layout.offLoading()
            }
        }
        , onAccount: function () {
            this.is_on_account = true
        }
        , onRequest: function () {
            this.is_on_request = true
        }
    }
    , created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
    }
    , watch: {
        'search.search_date_type': {
            immediate: true
            , handler: function (call) {
                if (!this.$layout) {
                    this.$layout = this.$root.$children[0].$children[0]
                    this.user = this.$layout.user
                }
                let today = this.date.getToday('-')
                this.$set(this.search, 'eDate', today)
                switch (call) {
                    case 'today':
                        this.$set(this.search, 'sDate', today)

                        this.getData()
                        break
                    case 'weekly':
                        this.$set(this.search, 'sDate', this.date.getLastDate(today, 7, '-'))

                        this.getData()
                        break
                    case 'month':
                        this.$set(this.search, 'sDate', this.date.getLastDate(today, 30, '-'))

                        this.getData()
                        break
                    case '3':
                        this.$set(this.search, 'sDate', this.date.getLastDate(today, 90, '-'))
                        break
                    case '6':
                        this.$set(this.search, 'sDate', this.date.getLastDate(today, 180, '-'))
                        break
                }
            }
        }
    }
}
</script>

<style>
.bg-icon-paper {
    background: url('../../assets/images/icon/icon-paper.svg') no-repeat 10px center;
    padding-left: 70px;
}

.bg-icon-paper-cancel {
    background: url('../../assets/images/icon/icon-paper-cancel.svg') no-repeat 10px center;
    padding-left: 70px;
}

.bg-icon-calc {
    background: url('../../assets/images/icon/icon-calc.svg') no-repeat 10px center;
    padding-left: 70px;
}
</style>